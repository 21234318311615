<template>
  <div>
    <div v-if="showContentOnMobile">
      <div
        class="evp-wrapper rounded-lg flex justify-around md:gap-20 md:pl-20 pt-12 md:pt-20"
      >
        <div class="md:w-1/2">
          <img
            :src="require('@/assets/images/evp/emp-benefits.svg')"
            class="h-10 md:h-14 mx-20 md:mx-10 mb-4"
          />
          <div class="circular-menu">
            <div class="menu-circle">
              <a
                v-for="category in computedEVPData"
                :key="category.slug"
                :id="category.slug"
                @click.prevent="activateCategory(category)"
                class="hidden"
              >
                <svg
                  :id="'cat-svg-' + category.slug"
                  width="170"
                  height="127.5"
                  viewBox="0 0 200 150"
                >
                  <defs>
                    <linearGradient id="gradient">
                      <stop offset="0" stop-color="#a7a9ac" />
                      <stop offset="100%" stop-color="#a7a9ac" />
                    </linearGradient>
                  </defs>
                  <path
                    :id="'cat-' + category.slug"
                    d="M30,75 Q100,46 170,75"
                    stroke="#C0CAE2"
                    stroke-width="75"
                    fill="none"
                  />

                  <image
                    :id="'cat-image-' + category.slug"
                    :href="require('@/assets/' + category.icon)"
                    class="w-10"
                  />
                </svg>
              </a>
            </div>
            <div
              class="centerContent font-semibold text-xl"
              v-if="selectedCategory.icon"
            >
              <img
                :src="require('@/assets/' + selectedCategory.centericon)"
                class="w-12"
              />
              <p class="w-full px-3 text-xl" v-if="selectedCategory.title">
                {{ selectedCategory.title }}
              </p>
            </div>
            <div class="centerContent font-semibold text-5xl pt-24" v-else>
              <p>EVP</p>
            </div>
          </div>
        </div>
        <div id="contentText" class="md:w-1/2 hidden">
          <div class="text-white md:mr-20">
            <div
              v-if="selectedCategory.title"
              class="px-10 md:px-14 py-3 text-left rounded-lg contentTitleCard relative mr-10 md:mr-0"
            >
              <div
                class="rounded-tr-lg rounded-br-lg contentTitleBG absolute h-14 w-2 top-0 -right-3"
              ></div>
              <div v-if="selectedCategory.icon" class="selectedIcon">
                <img
                  :src="require('@/assets/' + selectedCategory.icon)"
                  class="h-8 ml-3 mt-3"
                />
              </div>
              <p class="w-full font-bold text-lg md:text-2xl uppercase">
                {{ selectedCategory.title }}
              </p>
            </div>
            <div id="list-items"></div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="bg-nColorFillGray border border-nColorStrokeGray rounded-xl px-6 py-10"
    >
      <div class="w-full grid gap-10">
        <p class="text-lg text-black text-center font-bold">
          Rotate your phone
        </p>
        <div class="place-self-center">
          <lottie-animation
            path="lotties/mobile-rotate.json"
            :loop="true"
            :autoPlay="true"
            :speed="0.5"
            :width="200"
            :height="200"
          />
        </div>
        <p class="text-sm text-black text-center">
          Please use landscape mode for this experience
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import obkSlugs from "@/common/slugs";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import "animate.css";

export default {
  name: "RadialDial",
  components: { LottieAnimation },
  props: {
    evpData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      obkSlugs,
      selectedCategory: {
        title: null,
        icon: null,
        centericon: null,
        values: null,
      },
      showContentOnMobile: true,
    };
  },
  computed: {
    computedEVPData() {
      const categories = [];
      for (var key of Object.keys(this.evpData)) {
        if (this.evpData[key].length != 0) {
          categories.push({
            slug: key,
            label: this.obkSlugs.evpSlugs[key].label,
            icon: this.obkSlugs.evpSlugs[key].icon,
            values: this.evpData[key],
          });
        }
      }
      return categories;
    },
  },
  mounted() {
    var currentOrientation = window.screen.orientation.type;
    if (currentOrientation.includes("portrait")) {
      this.showContentOnMobile = false;
    } else {
      this.setupEVP();
    }
    let portrait = window.matchMedia("(orientation: portrait)");
    var self = this;
    portrait.addEventListener("change", function (e) {
      if (e.matches) {
        self.showContentOnMobile = false;
      } else {
        self.showContentOnMobile = true;
        // self.setupEVP();
        location.reload();
      }
    });
  },
  methods: {
    setupEVP() {
      var menuCircle = document.querySelector(".menu-circle");
      setTimeout(() => {
        if (menuCircle) {
          document.querySelector(".menu-circle").classList.add("open");
        }
      }, 500);
      var items = document.querySelectorAll(".menu-circle a");

      for (var i = 0, l = items.length; i < l; i++) {
        if (items[i].classList.contains("hidden")) {
          this.positionCircleElement(items, i, l);
        }
      }
    },
    positionCircleElement(items, i, l) {
      setTimeout(() => {
        items[i].style.left =
          (
            50 -
            25 * Math.cos(-0.5 * Math.PI - 2 * (1 / l) * i * Math.PI)
          ).toFixed(4) + "%";
        items[i].style.top =
          (
            50 +
            25 * Math.sin(-0.5 * Math.PI - 2 * (1 / l) * i * Math.PI)
          ).toFixed(4) + "%";
        items[i].style.transform = "rotate(" + i * (360 / l) + "deg)";
        document
          .querySelector("#cat-image-" + items[i].id)
          .setAttribute(
            "transform",
            "translate(85 44) rotate(-" + i * (360 / l) + ", 20, 20)"
          );
        items[i].classList.remove("hidden");
        items[i].classList.add("animate__animated", "animate__zoomIn");
      }, (i + 1) * 200);
    },
    closeModal() {
      this.$emit("closeModal", "cancel");
    },
    actionType(action) {
      this.$emit("closeModal", action);
      this.$emit(action);
    },
    activateCategory(category) {
      this.selectedCategory.title = category.label;
      this.selectedCategory.centericon = category.icon;
      this.selectedCategory.icon = category.icon.replace(
        "icons",
        "icons-selected"
      );
      this.selectedCategory.values = category.values;
      this.selectCategoryAndHighlight(category);
      var contentText = document.querySelector("#contentText");
      if (contentText.classList.contains("hidden")) {
        contentText.classList.remove("hidden");
        contentText.classList.add("animate__animated", "animate__fadeInRight");
        document
          .querySelector(".circular-menu")
          .classList.add("animate__animated", "animate__fadeInRight");
      } else {
        contentText.classList.add(
          "animate__animated",
          "animate__fast",
          "animate__zoomIn"
        );
      }
      contentText.addEventListener("animationend", () => {
        contentText.classList.remove(
          "animate__animated",
          "animate__fast",
          "animate__fadeInRight",
          "animate__zoomIn"
        );
      });
      this.animateValues(category.values);
    },
    animateValues(values) {
      for (var i = 0; i < values.length; i++) {
        this.createItemDivWithValue(values[i], i);
      }
    },
    createItemDivWithValue(value, index) {
      var listItemsDiv = document.getElementById("list-items");
      listItemsDiv.innerHTML = "";
      setTimeout(() => {
        var item = document.createElement("div");
        item.classList.add(
          "pr-10",
          "pl-4",
          "md:pr-20",
          "md:pl-6",
          "mr-10",
          "ml-2",
          "md:mr-0",
          "py-2",
          "text-left",
          "text-base",
          "md:text-lg",
          "text-black",
          "font-semibold",
          "rounded-lg",
          "my-2",
          "animate__animated",
          "animate__fadeInRight"
        );
        item.style = "background: #EAF2FF; border: 2px solid #fff;";
        item.innerHTML = value;
        listItemsDiv.appendChild(item);
      }, (index + 1) * 200);
    },
    selectCategoryAndHighlight(category) {
      for (var key of Object.keys(this.evpData)) {
        if (this.evpData[key].length != 0) {
          if (key == category.slug) {
            document
              .querySelector("#cat-" + key)
              .setAttribute("stroke", "#3D50E0");
          } else {
            document
              .querySelector("#cat-" + key)
              .setAttribute("stroke", "#C0CAE2");
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.evp-wrapper {
  height: 600px;
  background-image: url("~@/assets/images/evp/backgrounds/3.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.circular-menu {
  width: 378px;
  height: 378px;
  position: relative;
  border-radius: 50%;
  background: transparent;
}

.menu-circle {
  width: 385px;
  height: 385px;
  opacity: 0;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}
.open.menu-circle {
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
}

.menu-circle a {
  text-decoration: none;
  height: 225px;
  width: 168.75px;
  margin-left: -88px;
  margin-top: -115px;
  position: absolute;
  text-align: center;
  cursor: pointer;
  z-index: 999;
}
.centerContent {
  position: absolute;
  top: calc(50% - 120px);
  left: calc(50% - 120px);
  text-align: center;
  color: #ffffff;
  display: block;
  width: 240px;
  height: 240px;
  border-radius: 50%;
  background: #ff5670;
  z-index: 999;
}
.centerContent img {
  margin-left: calc(50% - 25px);
  margin-top: 75px;
}
.centerContent p {
  margin-top: 5px;
}
.show-content {
  display: block;
}
.slide-left {
  display: block;
  animation: 1s slide-left;
}
@keyframes slide-left {
  from {
    margin-right: 0%;
  }
  to {
    margin-right: 5%;
  }
}
.selectedIcon {
  text-decoration: none;
  background: #fff;
  border-radius: 50%;
  height: 70px;
  width: 70px;
  top: -8px;
  left: -35px;
  position: absolute;
  text-align: center;
  z-index: 999;
  border: 6px solid #fdb400;
}
.contentTitleCard {
  background: #3d50e0;
  border: 4px solid #fff;
}
.contentItemCard {
  border: 2px solid #7014f2;
}
.contentTitleBG {
  background: #fdb400;
}
</style>
