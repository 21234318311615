<template>
  <div class="evp-container relative h-full">
    <EVPRadialDial :evpData="evpData" />
  </div>
</template>

<script>
import EVPRadialDial from "./RadialDial.vue";

export default {
  name: "EVPContainer",
  components: { EVPRadialDial },
  created() {},
  mounted() {},
  props: {
    evpData: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped></style>
<style>
body {
  overscroll-behavior: none;
}
</style>
